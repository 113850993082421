<template>
    <MasterLayout :main_name="main_name" :link="link">
        <div class="sidebar_right_content profile_block">
            <div id="Mechanics" class="w3-container w3-border city">
                <ul class="ui-formSlide">
                    <!-- my profile first section -->
                    <li class="profile_col">
                        <form @submit.prevent="submitForm" method="post" enctype="multipart/form-data">
                            <!-- enctype="multipart/form-data" -->
                            <div class="profile_box">
                                <div class="profile_left">
                                    <div class="profile_inr_left">
                                        <div class="profile_image">
                                            <!-- <img v-if="form.profile" :src="form.profile"> -->
                                            <!-- <img v-else :src="previewImage" /> -->
                                            <!-- <img :src="url" /> -->
                                            <!-- <img :src="authUser.profile"> -->
                                            <img v-if="url" :src="url" />
                                            <img v-else :src="previewImage" />
                                        </div>
                                        <div class="middle">
                                            <label for="profile" class="form-label mb-0">
                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                            </label>
                                            <input type="file" id="profile" name="profile" class="mb-2" ref="file"
                                                @change="onImageChange" hidden />
                                        </div>
                                    </div>
                                </div>
                                <div class="profile_right">
                                    <div class="profile_inr_right">
                                        <div class="profile_form_box">
                                            <div class="profile_12">
                                                <div class="profile_12_lt">
                                                    <div class="profile_icon">
                                                        <img :src="user_icon">
                                                    </div>
                                                </div>
                                                <div class="profile_12_rt">
                                                    <label class="profile_name">
                                                        <span>First name</span>
                                                    </label>
                                                    <input type="text" name="first_name" v-model="form.first_name" @keyup="form.first_name.length > 0
                                                        ? [(eventFirstNameError = 'none'), (disabled = false)]
                                                        : (eventFirstNameError = 'block')
                                                        ">
                                                </div>
                                            </div>
                                            <div class="profile_12">
                                                <div class="profile_12_lt">
                                                    <div class="profile_icon">
                                                        <img :src="user_icon">
                                                    </div>
                                                </div>
                                                <div class="profile_12_rt">
                                                    <label class="profile_name">
                                                        <span>Middle Name</span>
                                                    </label>
                                                    <input type="text" name="middle_name" v-model="form.middle_name">
                                                </div>
                                            </div>
                                            <div class="profile_12">
                                                <div class="profile_12_lt">
                                                    <div class="profile_icon">
                                                        <img :src="user_icon">
                                                    </div>
                                                </div>
                                                <div class="profile_12_rt">
                                                    <label class="profile_name">
                                                        <span>Last name</span>
                                                    </label>
                                                    <input type="text" name="last_name" v-model="form.last_name" @keyup="form.last_name.length > 0
                                                        ? [(eventLastNameError = 'none'), (disabled = false)]
                                                        : (eventLastNameError = 'block')
                                                        ">
                                                </div>
                                            </div>
                                            <div class="profile_12">
                                                <div class="profile_12_lt">
                                                    <div class="profile_icon">
                                                        <img :src="call_icon">
                                                    </div>
                                                </div>
                                                <div class="profile_12_rt">
                                                    <label class="profile_name">
                                                        <span>Phone Number</span>
                                                    </label>
                                                    <input type="text" name="mobile" v-model="form.mobile" @keyup="form.mobile.length > 0
                                                        ? [(eventMobileError = 'none'), (disabled = false)]
                                                        : (eventMobileError = 'block')
                                                        " readonly>
                                                </div>
                                            </div>
                                            <div class="profile_12">
                                                <div class="profile_12_lt">
                                                    <div class="profile_icon">
                                                        <img :src="mail_icon">
                                                    </div>
                                                </div>
                                                <div class="profile_12_rt">
                                                    <label class="profile_name">
                                                        <span>Email Id</span>
                                                    </label>
                                                    <input type="email" name="email" v-model="form.email">
                                                </div>
                                            </div>
                                            <br />
                                            <div class="btnCenter">
                                                <button type="submit" name="submit" id="submit"
                                                    class="main_button_user_square_plus" :disabled="disabled">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </li>
                    <!-- end my profile first section -->
                </ul>
            </div>
        </div>
    </MasterLayout>
</template>

<script>
import MasterLayout from './MasterLayout.vue';
// import profile_image from '../../assets/images/User/profile-image.jpg';
import user_icon from '../../assets/images/User/user-icon.png';
import call_icon from '../../assets/images/User/call_icon.png';
import mail_icon from '../../assets/images/User/mail-icon.png';
import previewImage from '../../assets/images/User/avatar001.jpg';
import axios from "axios";
import Form from 'vform';

export default {
    name: 'MyProfile',
    components: { MasterLayout },
    data() {
        return {
            main_name: 'MyProfile',
            link: [
                { 'link': 'home', 'name': 'Home' },
            ],
            // profile_image: profile_image,
            previewImage: previewImage,
            user_icon: user_icon,
            call_icon: call_icon,
            mail_icon: mail_icon,
            form: new Form({
                first_name: null,
                middle_name: null,
                last_name: null,
                mobile: null,
                profile: null,
                email: null,
            }),
            url: null,
            response: "",
            disabled: false,
            eventFirstNameError: "none",
            eventLastNameError: "none",
            eventMobileError: "none",
            token: localStorage.getItem('auth_cus_token'),
        }
    },
    mounted() {
        this.getUserProfile();
        window.scrollTo({ top: 0, behavior: "smooth" });
    },
    methods: {
        onImageChange(e) {
            let img = e.target.files[0]
            this.form.profile = img;
            this.url = URL.createObjectURL(img);
            console.log(this.url);
        },
        async submitForm() {
            var newForm = new FormData();
            newForm.append('first_name', (this.form.first_name || this.form.first_name != '') ? this.form.first_name : '');
            newForm.append('last_name', (this.form.last_name || this.form.last_name != '') ? this.form.last_name : '');
            newForm.append('middle_name', (this.form.middle_name || this.form.middle_name != '') ? this.form.middle_name : '');
            newForm.append('mobile', this.form.mobile);
            newForm.append('email', this.form.email);
            newForm.append('profile', this.form.profile);

            await axios.post(process.env.VUE_APP_API_CUS_URL + "/user-update", newForm,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Accept": "application/json",
                        "Authorization": `Bearer ${this.token}`
                    },
                })
                .then((response) => {
                    if (response.data.status) {
                        this.$toast.success(response.data.message);
                        this.disabled = false;
                        this.getUserProfile();
                    } else {
                        this.$toast.error(response.data.error?.[0]);
                        this.disabled = false;
                    }
                })
                .catch((error) => {
                    this.$toast.error(error);
                    this.disabled = false;
                });
        },
        async getUserProfile() {
            this.loader = true;
            await axios.get(process.env.VUE_APP_API_CUS_URL + "/user-profile",
                {
                    headers: {
                        "Content-Type": "application/json",
                        'Accept': "application/json",
                        "Authorization": `Bearer ${this.token}`
                    }
                })
                .then((response) => {
                    this.form = response.data.data;
                    this.form.first_name = (this.form.first_name || this.form.first_name == '') ? this.form.first_name : ''
                    this.form.middle_name = (this.form.middle_name || this.form.middle_name == '') ? this.form.middle_name : ''
                    this.form.last_name = (this.form.last_name || this.form.last_name == '') ? this.form.last_name : ''
                    if(response.data.data.profile) {
                        this.previewImage = response.data.data.profile
                    }
                }).catch((e) => {
                    if (e.response.status == 401) {
                        localStorage.removeItem('auth_otp');
                        localStorage.removeItem('auth_user_type');
                        localStorage.removeItem('auth_cus_token');

                        this.$router.push({ name: 'home' });
                        window.location.href = "/";
                    }
                });
        },
    }
}
</script>

<style scoped>
.btnCenter {
    text-align: center !important;
}

.profile_inr_left:hover img {
    opacity: 0.6;
}

.profile_inr_left:hover .middle {
    opacity: 1;
}

.middle {
    transition: .4s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.middle i {
    font-size: 30px;
    cursor: pointer;
}

.editIbtn {
    color: #000;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 50%;
    background-color: #42aea3;
}

input[type=file] {
    display: none;
}

.profile_image {
    background-color: #fff;
    border-radius: 50%;
}
</style>

<!-- axios.get('/admin/occupationspeciality/edit/' + this.details.id)
                .then(function (response) {
                    // console.log('response: ', response);
                    if (response !== undefined) {
                        let data = response.data.data;
                        // console.log(data.occupation_id);
                        if (data.status == 'error') {
                            $this.$router.push({ name: "admin.occupation" });
                            $this.showError(data.message);
                        } else {
                            $this.details.id = data.id;
                            $this.details.occupation_id.push(data.occupation_id);
                            // $this.details.occupation_id = data.occupation_id;
                            $this.details.speciality_id = data.speciality_id;
                            $this.$store.state.errors = [];
                        }
                    }
                })
                .catch(function (error) {
                    $this.errorHandler();
                }); -->